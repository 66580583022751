.clihead {
  font-family: "Bebas Neue";
    color: #0765bd;
    font-size: 1.5rem;
    text-align: center;

}
.clihold h1{
  font-size: 2rem;
  font-family: "Bebas Neue";
}


.clihold{
  overflow-x: hidden;
  overflow-y: hidden;
}
