.Cli{
   
    width:100%;

scroll-behavior: none;
background-image:linear-gradient(to right, rgba(0, 0, 0, 0.665), rgba(0, 0, 0, 0.665)),url("./../../Asset/KL.jpg") ;
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover; 
background-attachment: fixed;
}

.cim{
width:7rem;
object-fit: cover;

}

.dpe{

    backdrop-filter: blur(5px);
    padding:2rem;
}

.row{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;  
gap:4rem;
padding:2rem  
}


.kke{
    display: flex;
    justify-content: center;
    align-items: center;
     font-family: "Bebas Neue";
   color: rgb(255, 183, 0);
    font-size:1.5rem;
    
}

.GLLG
{   text-align: center;
    color:rgb(255, 183, 0);
     font-family: "Bebas Neue";
    font-size: 2rem;
}

.ic{
    background-color: white;
display: flex;
justify-content: center;
align-items: center;
border-radius: 2rem;
 padding:1.5rem;
 border:7px double rgb(255, 183, 0)
;
transition: transform 0.4s ease-in-out;
}

.ic:hover{
transform: scale(1.2);
}

@media screen and (max-width:768px){

    .row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;  
        gap:1rem;
  
        }
       
        .GLLG,.kke
{  
    position: relative;
    top:2rem;
    margin:1rem;
}

.ic{
 
 padding:1rem;
 border:7px double rgb(255, 183, 0)
;
transition: transform 0.4s ease-in-out;
}
}