.prdct1{
    padding: 2rem;
    display: block;
    background-image:linear-gradient(to right, rgba(0, 0, 0, 0.665), rgba(0, 0, 0, 0.665)),url("./../../Asset/slider2.jpg") ;
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover; 
background-attachment: fixed;
    
}

.prdct2
{
display: flex;
justify-content: space-around;
align-items: center;
gap:1rem;
}

.pdim{
    height:10rem;
    width:10rem;
    border-radius: 50%;
    border:3px solid #0765bd;
    padding:0.5rem;
    transition:  0.4s ease-in-out;

}

.pdimd{
    border-radius: 50%;

}

.prdli:hover .pdim{
border-radius: 100%;
transform: scale(1.2);
border:5px double rgb(255, 183, 0);
}

.prdli{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: rgb(255, 183, 0);
    font-family: "Cabin Sketch";
    font-size: 1.2rem;
    margin: 1rem;
}

.PRd
{   text-align: center;
    color:rgb(255, 183, 0);
    font-family: "Cabin Sketch";
    font-size: 1.7rem;
}
@media screen  {

    .prdct2
{
display: flex;
flex-direction: column;
width: 100%;
flex-wrap: wrap;
justify-content: space-around;
align-items: center;
gap:1rem;
}

   
.prdli:hover .prdh5{
  position: relative;
  top:1.2rem
    }

}