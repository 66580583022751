.blogs{
padding:2rem;
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: center;
align-items: center;
}

.bgs{
    display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
gap:5rem;
}

.heding{

color: #b6351b;
    font-family: Roboto,sans-serif;
}



.box{
   
    overflow: hidden;
    display: flex;
    flex-direction: column;
   
    width:20rem;
    height: 24rem;
    border : 1px double #b6351b;;
    border-radius:10px ;
    
}

.pi{
    /*
    height: 10rem;
    width:12rem;
    */
    height:100%;
    width:100%;
    position: relative;
  
    transition: transform 0.4s ease-in-out;
}

.ph {
  
    width:100%;
    transition: transform 0.4s ease-in-out;
    text-align:left;
    font-family: Roboto,sans-serif;
   color: #204c79;
    font-size: 1rem;
    opacity: .98;
    text-align: left;
    text-transform: capitalize;
    position: relative;
          padding-top:1rem;
     padding-left:.7rem
}

.dt{
     transition: transform 0.4s ease-in-out;
    text-align:left;
    font-family: Roboto,sans-serif;
   color: #204c79;
    font-size: 0.8rem;
    opacity: .98;
    text-align: left;
    text-transform: capitalize;
    position: relative;
          padding-top:1rem;
     padding-left:.7rem 
}

.box:hover {
   
    border : 1px double #204c79;
}

.box:hover .ph {
   transform: translateY(1.1rem);
}
.box:hover .pi {
    
transform: scale(1.2);

}

.box:hover .dt {
    
   transform: translateY(0.9rem);

}
.box:hover .btn {
    
    display:block;
    transform: translateY(0.6rem);
     border : 0.3px double #204c79;
}

.btn{
    background: transparent;
    padding:0.4rem;
   margin-top:-0.5rem;
     margin-left:0.5rem;
    text-align: left;
    font-style:"Roboto,sans-serif" ;
    transition: transform 0.4s ease-in-out;
   position: relative;
     border : 0.3px double #b6351b;
     border-radius: 10px;
     width:5rem;
   text-decoration: none;
   color: #b6351b;;
   font-size: 0.8rem;
   font-weight: bold;
}




@media screen and (max-width: 768px)
{

.blogs{
 box-sizing: border-box;
 padding:0.5rem
}

.box{
   
    width:16rem;
    height: 24rem;
}

}

@media screen and (max-width: 240px)
{


}

@media screen and (max-width: 380px)
{


}