.footer {
    padding-bottom: 1rem;
  
    gap:1rem;
    border: 1px solid black;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Adjust alignment */
    position: relative;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.665), rgba(0, 0, 0, 0.665)),
      url('./../../Asset/slider3.jpg');
      background-attachment: fixed;
      bottom:0;
  }

  .footer1{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding:1rem;
  }

  .footer2{
    color:white;
    font-family: "Poppins";
font-size: 0.8rem;
    text-align: center;
    position: relative;


  }
  
  .column {
    flex: 0.5; /* Distribute available space equally among columns */
  }
  
  .in,
  .cd,
  .ql,
  .ap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
   
    flex-wrap: wrap;
    max-width: 20%; /* Adjust the maximum width as needed */
  }
  
  .cd,
  .ap{
    position: relative;
    left:2rem
  }



  .ql
  {
    position: relative;
    left:4rem
  }

.fi{
    height: 6rem;
    width:6rem;
}

.fh4{
    color:white;
    font-family: "Poppins";
}

.fh5{
    color:rgb(255, 183, 0);
    font-family: "Poppins";
    font-weight: BOLD;
    text-decoration: NONE;
}
  
.fp{
    color:rgb(255, 183, 0);
    font-family: "Poppins";
    font-weight: BOLD;
    text-align: left;
    background-color: transparent;
    backdrop-filter: (5px);
    font-size: 0.9rem;

   
    
}


.fp2{
    color:rgb(255, 255, 255);
    font-family: "Poppins";
    
    text-decoration:none;
    backdrop-filter: (5pX);
    font-size: 0.8rem;
   
}


.hu{
    list-style:none;
    line-height: 1.5rem;
    margin-left: 20px;
    color:white;
    text-decoration: none;

}

.hu2{
    list-style:none;
    line-height: 1rem;
    margin-left: 20px;
    font-size: 0.8REM;
    color:white;
    text-decoration: none;

}

.hu2 li::before {
    content: "⚡"; /* Unicode for the Font Awesome icon you want to use */
   /* Specify the Font Awesome font family */
    margin-left: -25px; /* Adjust spacing between the symbol and the text */
   
  }

.hu li::before {
    content: "⚡"; /* Unicode for the Font Awesome icon you want to use */
   /* Specify the Font Awesome font family */
    margin-left: -25px; /* Adjust spacing between the symbol and the text */
   
  }

  .apal{

    text-decoration: none;
    color: white;
  }

  .hrt{
    height: 0.8rem;
    width: 0.8rem;
  }



  input[type=text] {
    width: 90%;
    padding:5PX;
    margin: 2px 0;
    box-sizing: border-box;
  }

  input[type=email] {
    width: 90%;
    padding: 5PX;
    margin: 2px 0;
    box-sizing: border-box;
  }

 textarea {
    width: 90%;
    padding:5PX;
    margin: 2px 0;
    box-sizing: border-box;
    
  }

  @media screen and (max-width:768px) {
    .footer
    {
      padding-bottom: 1rem;
      border: 1px solid black;
    
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; /* Adjust alignment */
      position: relative;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.665), rgba(0, 0, 0, 0.665)),
        url('./../../Asset/slider3.jpg');
        background-attachment: fixed;
      left:-1rem;
        padding: 1rem;
        max-width:100%;
    }

        .footer1{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
        
        }
      

        .footer2{
          color:white;
          font-family: "Poppins";
      font-size: 0.8rem;
          text-align: center;
          position: relative;
      
      
        }
    
        .in,
        .cd,
        .ql,
        .ap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
         
          flex-wrap: wrap;
          max-width: 100%; /* Adjust the maximum width as needed */
        }

        .fp{
          text-align: center;
        }
        .cd,
        .ap,.ql{
          position: relative;
          left:0rem
        }

        

  input[type=text] {
    width: 110%;
    padding:5PX;
    margin: 2px 0;
    box-sizing: border-box;
  }

  input[type=email] {
    width: 110%;
    padding: 5PX;
    margin: 2px 0;
    box-sizing: border-box;
  }

 textarea {
    width: 110%;
    padding:5PX;
    margin: 2px 0;
    box-sizing: border-box;
    
  }
  }