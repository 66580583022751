.container {
    height: 28rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.665), rgba(0, 0, 0, 0.665)),
    url('./../../Asset/slider3.jpg');
    background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
    top: -1rem;

    padding-top: 2rem;
 
  }
  
  .abt {
    height: 27.5rem;
    
    display: block;
 position:relative;
  align-items: center;
  place-items: center;
    overflow: hidden;
    top:0%;
    left:0%
  }
  
  .ab1 {
    display: flex;
    justify-content: left;
    align-items: left;
    height: 13rem;
    width: 13rem;
    background: url("./../../Asset/kk.jpg");
    background-attachment: fixed;
    border-radius: 2rem;
    float: left;
    position: relative;
    top: 6rem;
    left: 6rem;
    z-index: 1;
  }
  
  .ab2 {
    display: flex;
    height: 15rem;
    width: 15rem;
    background-attachment: fixed;
    background: url("./../../Asset/p6.webp");
    border-radius: 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    top: 2rem;
    left: -5rem;
    z-index: 2;
  }
  
  .ab3 {
    display: flex;
    height: 22rem;
    width: 40rem;
    border: 4px double rgb(255, 183, 0);
    border-radius: 2rem;
    backdrop-filter: blur(2.5px);
    color: white;
    font-weight: 800;
    position: relative;
    top: -15rem;
    left: 15rem;
    z-index: 0;
    padding: 1rem;
    padding-left: 11rem;
    
  }
  
  .ab31 {
    position: relative;
    width: 48rem;
    display: flex;
    flex-direction: column;
    padding: auto;
  }
  
  .abp {
    position: relative;
    top: 0rem;
    font-family: "Poppins";
    font-size: 0.8rem;
  }
  
  .abh {
    position: relative;
    top: -0.5rem;
    color: rgb(255, 149, 0);
    font-family: "Cabin Sketch";
    text-align: center;
    font-size: 1.28rem;
  }
  

  @media screen and (max-width:768px) {
    

    
  .ab1 {
    display: flex;
    justify-content: left;
    align-items: left;
    height: 10rem;
    width: 10rem;
    background: url("./../../Asset/kk.jpg");
    background-attachment: fixed;
    border-radius: 2rem;

    position: relative;
    top: 2rem;
    left: 7rem;
    z-index: 1;
  }
  
  .ab2 {
    display: flex;
    height: 10rem;
    width: 10rem;
    background-attachment: fixed;
    background: url("./../../Asset/p6.webp");
    border-radius: 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    top: 1rem;
    left: -2rem;
    z-index: 2;
  }
  
  .ab3 {
    display: flex;
    height: auto;
    width: 19rem;
    border: 4px double rgb(255, 183, 0);
    border-radius: 2rem;
    backdrop-filter: blur(2.5px);
    color: white;
    font-weight: 800;
    position: relative;
    top: 3rem;
    left: 1rem;
    z-index: 5;
    padding: 1rem;
   
    
  }
  .container {
    height: 63rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.665), rgba(0, 0, 0, 0.665)),
    url('./../../Asset/slider3.jpg');
    background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
    top: -1rem;
  
    padding-top: 2rem;
    
  }
  
  .abt {

    
    display: block;
 position:relative;
  align-items: center;
  place-items: center;
    overflow: hidden;
    top:0%;
    left:0%;
    height:63rem;
  }

    
  .abp {
    position: relative;
    top: 0rem;
    font-family: "Poppins";
    font-size: 0.8rem;
  }

  }
  