
*{
  margin:0;
  padding:0;
}

.list{
  text-decoration: none;
  font-size: 1.5rem;
  color: black;
  color: #0765bd;
  font-family: "Bebas Neue";
  font-weight: 500;
  position: relative;
  top:0.8rem;
  gap:1.5rem;
  

}



.mq{
  position: relative;
  top:-2rem;
  width:60vw;
  height:0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem ;
  font-weight: bold;
  color:orangered;
  border-right: 50%;
  border-left: 50%;
  padding-bottom:0.5rem

}
.ul {
    list-style-type: none;
  position: relative;
  top:-1rem;
    padding: 0.1rem;
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    color: #0765bd;
    

  }

  .list:hover{
    color:rgb(255, 183, 0);
  }



    .img1{
   
      margin-left:.4rem;
      margin-top: -3rem;
      height:7rem;
      width:7rem;
    }

.l3{
  display:flex;
  justify-content: center;
  align-items: center;
  padding:1rem;
  width:9rem;
}



.nav {
display: flex;
flex-direction: column;
align-items: center;
place-items: center;
justify-content: space-around;
color:black;
background: rgb(242, 255, 254);
position: relative;
top:-3.5rem;
width:100vw;
z-index:20;
LEFT:0REM;
height: 6rem;
}

.nasv{
position: fixed;
top:0;
z-index:20;
background: rgb(242, 255, 254);
height: 7.5rem;
box-shadow: 0px 0.05rem rgb(255, 94, 0);

}
.nav__brand {
text-transform: uppercase;
color:black;
}

.nav__menu {
display: flex;
align-items: center;
color:black;
justify-content: center;

}
.nav__toggler {
display: none;
}
.nav__toggler div {
  color:black;
width: 2.5rem;
height: 0.2rem;
margin: 0.4rem;

background:#0765bd;
transition: 0.3s ease-in;

position: relative;
}

.dropdown {
  position: relative;
  display: inline-block;
  /*color: #f1f1f1;*/
  color: black;
  font-size: 1.2rem;
}

.dropdownContent {
  margin-top: 1rem;
}

.dropdown-content a {
  color: white;
  background-color: #0765bd;
  padding: 0.4rem;
  text-decoration: none;
  display: block;
  font-size: 1.1rem;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width:10rem;
  max-width:max-content;
margin-top: 1.5rem;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 0.5rem;
  font-size: 1.1rem;
  text-align: center;
  font-family: "Bebas Neue";
  background-color:  #0765bd;
  padding:1rem;
  line-height: 1rem;
 
  color: white;

}

.dropdown-content a:hover {
background-color: orangered;
color: white;
padding:0.5rem;
font-family: "Bebas Neue";
font-size: 1.1rem;
line-height: 1rem;
border-radius: 20px;
}

.dropdown:hover .dropdown-content {
display: block;
}

.nd{
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
width: 100vw;
height:4rem;
margin-top: 2.5rem;
/*border:1px solid black;*/
}

.header{
  position: relative;
  display: flex;
width: 100vw;
align-items: left;
  justify-content: space-around;
/*border:1px solid black;*/
  margin-top:3.5rem;
  background-color: orangered;
  color:white
  
}

.h1{
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: left;
/*border:1px solid black;*/
  position: relative;
  left:-5rem;


}

.derr{
 position: relative;
 top:-0.5rem;
}

.ss{
  position: relative;
  display:flex;
  justify-content:flex-end;
  gap:0.5rem;
 
}


.ims{
  width: 1.5rem;
  height:1.5rem;
}

.mail{
    color:white;
    text-decoration: none;
    font-family: "Hind";
    font-weight: 800;
    font-size: 0.9rem;
}

.or {
color: orange;
}

@media screen and (max-width:768px){

.nav{
  position:fixed;
  background: #f9f9f9;
 left:0;
  margin-left:0rem;
  width:100%;
  top:2rem;
}

.nasv{
  position: fixed;
  top:0;
  z-index:20;
  background: rgb(242, 255, 254);
  height: 5em;
  box-shadow: 0px 0.05rem rgb(255, 94, 0);
  
  }
.nav__toggler {
  display: block;
  cursor: pointer;
  position: relative;
  top:-6.3rem;
  left:40%;
}
.nav__menu {

  position: fixed;
  top: 4rem;
  right: 0;
  left:0;
  height: 70vh;
  width: 100%;
  background: rgb(255, 255, 255);
  flex-direction: column;
z-index:25;
  transform: translateX(120%);
  transition: 0.5s ease-in;
}



.img1{
  margin-left: 0.5rem;
  margin-top: 0.8rem;
  width:4rem;
  height:4rem;
  position:relative;

}

.l3{
  display: none;
}

.header{
  position: relative;
  display: flex;
  flex-direction: column;
width: 100%;
align-items: center;
  justify-content: space-around;
/*border:1px solid black;*/
  margin-top:3.5rem;
  background-color: orangered;
  color:white
  
}

.h1{
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
/*border:1px solid black;*/
  position: relative;
  left:0rem;


}

.mail{
  color:white;
  text-decoration: none;
  font-family: "Hind";
  font-weight: 600;
  font-size: 0.9rem;
}

.mail1{
  position: relative;
  font-size: 1rem;
  top:0.5rem
}

.mq{
  position: relative;
  top:-4rem;
  width:60vw;
  height:0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem ;
  font-weight: bold;
  color:orangered;
  border-right: 50%;
  border-left: 50%;
  padding-bottom:0.5rem

}

.mail2{
  font-size: 1rem;
position: relative;
top:-1rem
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 15rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
margin-left:-5rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  text-align: left;
margin-top: -4rem;

}

.nd{
margin-top:1rem
}

.ul {
list-style-type: none;
margin: 0;
padding: 0.1rem;
display: flex;
flex-direction: column;
gap: 1.5rem;
justify-content: center;



}

.icon{
  position: relative;
  top:-2rem;
}



}

/* Active Class */
.nav__active {
transform: translate(0%, 0%)
}

/* Toggle Icon Animation */

.icon{
  position: relative;
  top:-5rem;
}

.toggle .line1 {
transform: rotate(-45deg) translate(4px, 5px);
z-index: 35;
}
.toggle .line2 {
opacity: 0;
}
.toggle .line3 {
transform: rotate(45deg) translate(-4px, -5px);
z-index: 35;
}