.slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    background-image:linear-gradient(to right, rgba(0, 0, 0, 0.665), rgba(0, 0, 0, 0.665)),url("./../../Asset/sub-main-circuits.jpg") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
   background-attachment: fixed;
  }
  
  .box img {
    width: 100%;
    height: 100%;
  }
  
  .img-container {
    position: relative;
    height: 400px;
    width: 400px;
    left:7%;
/*perspective: 500PX;*/
    transform-style: preserve-3d;
  }
  
  .box {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 1000px;
    height: 400px;
    overflow: hidden;
    border-radius: 15px;
    border: 1px solid #bbb;
    position: absolute;
    top: 50%;
    transition: 800ms ease-in-out;
  }
  
  .box:nth-of-type(1) {
    z-index: 5;
    opacity: 0;
    left: 15%;
    transform: translate(-50%, -50%) rotateY(-10deg);
  }
  
  .box:nth-of-type(2) {
    opacity: 1;
    left: 20%;
    transform: translate(-50%, -50%) rotateY(-10deg);
    z-index: 5;
  }
  
  .box:nth-of-type(3) {
    opacity: 0.75;
    left: 25%;
    color: #eee;
    z-index: 10;
    transform: translate(-50%, -50%) rotateY(-10deg) translateZ(-50px);
  }
  
  .box:nth-of-type(4) {
    z-index: 5;
    opacity: 0.5;
    left: 30%;
    transform: translate(-50%, -50%) rotateY(-10deg) translateZ(-100px);
  }
  
  .box:nth-of-type(5) {
    z-index: 2;
    opacity: 0.25;
    left: 35%;
    transform: translate(-50%, -50%) rotateY(-10deg) translateZ(-150px);
  }
  
  .box:nth-of-type(6) {
    z-index: 2;
    opacity: 0.0;
    left: 40%;
    transform: translate(-50%, -50%) rotateY(-10deg) translateZ(-200px);
  }
  
  @media screen and (max-width:768px) {


    .slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
    position: relative;
    background-image:linear-gradient(to right, rgba(0, 0, 0, 0.665), rgba(0, 0, 0, 0.665)),url("./../../Asset/sub-main-circuits.jpg") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
   background-attachment: fixed;
  }

  
  .img-container {
    position: relative;
    height: 8rem;
    width:6rem;

/*perspective: 500PX;*/
  
  }
  
  .box {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 100vw;
    height:15rem;
    overflow: hidden;
    border-radius: 15px;
    border: 1px solid #bbb;
    position: absolute;
    top: 50%;
left:5%;
    transition: 800ms ease-in-out;
  }

  }