.products{
    display: block;
    padding:1rem;

}

.pl1{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap:1.5rem;
}

.pr{
    align-items: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:13rem;
    height: 14rem;
    border : 4px double rgb(255, 183, 0);
    padding-top:3rem;
}

.pi{
    height: 10rem;
    width:12rem;
    position: relative;
    top:-2.5rem;
    transition: transform 0.4s ease-in-out;
}

.ph {
    background-color: white;
    width:100%;
    transition: transform 0.4s ease-in-out;
    text-align: center;
    font-family: "Cabin Sketch";
    color: #0765bd;
    font-size: 1.5rem;
    
}

.pr:hover {
    padding-top: 3.5rem;;
    border : 4px double #0765bd;
}

.pr:hover .ph {
    transform: translateY(-2rem);
    z-index: -1;
}
.pr:hover .pi {
transform: scale(0.8);
    border : 4px double rgb(255, 183, 0);
    z-index:4;
}
.pr:hover .btn {
    transition: transform 0.4s ease-in-out;
    transform: translateY(-1rem);
    border : 4px double rgb(255, 183, 0);
    display:block;
}

.btn{
    background: transparent;
    padding:0.5rem;
    text-align: center;
    font-style:"Poppins" ;
    transition: transform 0.4s ease-in-out;
   position: relative;
   transform: translateY(3rem);
   text-decoration: none;
   color: black;
   font-size: 0.9rem;
}



@media screen and (max-width:768px)
{

    .products{
        display: block;
        padding:1rem;
        position: relative;
        left:-1rem;
        width:98%;
    }

    .pl1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        gap:1.5rem;
      
      
    }
}