/* Shared styles */

.thumb-bar {
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding:2rem;
    gap: 1.5rem;
  }
  
  .thumb-bar img {
    display: block;
    width: 22%;
    float: left;
    padding: 0.2rem;
    cursor: pointer;
    border: 3px solid rgb(255, 183, 0);
    transition: transform 0.4s ease;
    border-radius: 20px;
  }
  
  .gi {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
  }
  
 
  
  .gi:hover .displayed-img,
  .thumb-bar img:hover {
    transform: scale(1.1, 1.1);
  }
  
  .hed1 {
    margin-top: 2rem;
  }
  
  .j1 {
    background-image:linear-gradient(to right,rgba(0, 0, 0, 0.578) ,rgba(0, 0, 0, 0.349)), url(./../../Asset/slider1.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  
  .gh2 {
    color: rgb(255, 183, 0);
    font-family: "Cabin Sketch";
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 1rem;
  }

  
  /* ... (your existing styles) */

.modal-overlay {
    position: fixed;
    top: 2rem;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }
  
  .modal-content {
    position: relative;
    text-align: center;
    width: 80vw; /* Adjust as needed */
    height: 80vh; /* Adjust as needed */
    overflow: hidden;
  }
  
  .selected-img {
    border-radius: 2rem;
    margin-top: 0rem;
    border: 3px solid rgb(255, 183, 0);
    transition: transform 0.4s ease;
    width: 20rem;
    height: 16rem;
    object-fit: contain;
    position: relative;
    padding: 0.5rem;
    top:5rem; /* Preserve aspect ratio */
  }
  
  .close-button {
    background-color: red;
    color: white;
    padding: 0.5rem;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 5rem;
    right: 1rem;
  }
  


  @media screen and (max-width:768px) {
    
    .thumb-bar img {
      display: block;
      width: 38%;
      float: left;
      padding: 0.2rem;
      cursor: pointer;
      border: 3px solid rgb(255, 183, 0);
      transition: transform 0.4s ease;
      border-radius: 20px;
    }
    
    .modal-content {
      position: relative;
      text-align: center;
      width:60vh; /* Adjust as needed */
      height: 80vh; /* Adjust as needed */
      overflow: hidden;
    }
  }