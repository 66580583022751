
.patcontain {
    padding: 2rem;
}


.pathold {

    display: flex;
    text-align: center;
    flex-direction: column;
    width: 11rem;
    height: 13.5rem;
    border: none;
    border-radius:0 0 2rem 0;
    box-shadow: 1px 1px 10px 1px rgb(171, 170, 170);
}

.patimgHold {
    display: flex;
    justify-content: center;
    margin: 1rem;
}


.patimg {
    width: 15rem;
    height: 8rem;

}



@media screen and (max-width:768px) {
 

    .pathold {

        display: flex;
        text-align: center;
        flex-direction: column;
        width: 100%;
        height: 15rem;
        border: none;
        font-size: 0.8rem;
        box-shadow: 1px 1px 10px 1px rgb(171, 170, 170);
    }

    .patimgHold {
        display: flex;
        justify-content: center;
        margin: 1rem;
    }
    
}