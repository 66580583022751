.fl{
    display: flex;
    justify-content: space-evenly;
        align-items: center;
    padding:1rem;

    

}



.card{
  width:19rem;
  height:27rem;
 
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
    border-radius: 1.1rem;
    background:white;
    transition: transform 0.4s ease-in-out;

}



.c1{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    background-color:rgb(255, 255, 255);
    backdrop-filter: blur(2px);
    border-radius: 2rem;
    padding:1rem;
}

.imgs{
    height:4rem;
    width:4rem;
}

.hd{
    color: #0765bd;
    font-weight: bolder;
    font-family: "Poppins";
}


.wu{
    list-style:none;
    line-height: 1.5rem;
}

.wu li::before {
    content: "⚡"; /* Unicode for the Font Awesome icon you want to use */
   /* Specify the Font Awesome font family */
    margin-left: -25px; /* Adjust spacing between the symbol and the text */
  }
  .hed{
    color: #0765bd;
  font-family: "Bebas Neue";
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
}


.card:hover{
    transform: scale(1.05);
}
.card:hover .c1{
padding:2rem;
border: 2px double rgb(255, 183, 0)
}

.card:hover .hd{
    color: rgb(255, 183, 0);
}


@media screen and (max-width:768px) {
    .fl{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
            align-items: center;
            gap:1rem;
        padding:1rem;
    }  
        
        .wu{
            list-style:none;
            line-height: 1.5rem;
            text-align: left;
        }
    
       
}