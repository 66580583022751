.f2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;
    gap: 1rem;
}

.hed {
    color: #0765bd;
  font-family: "Bebas Neue";
    display: flex;
    text-align: center;
    justify-content: space;
    align-items: center;
    font-size: 2rem;
}

.de {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 12rem;
    height: 15rem;
    border: 2px double #0765bd;
    padding: 2rem;
    border-radius: 25px;
    transition: transform 0.8s ease-in-out;
    backdrop-filter: blur(1px);
}

.ci {
    height: 4rem;
    width: 4rem;
}

.hdd {
  font-family: "Bebas Neue";
    color: #0765bd;
    font-size: 2rem;
    text-align: center;
    transition: transform 2s ease-in, color 2s ease-in-out;
}

.cp {
    color: black;
    font-family: "poppins";
    text-align: center;
    position: relative;
    font-size: 0.8rem;
    top: -1rem;
    display: none;

}



.me:hover .de {
 transform: scale(1.1);
 height:18rem;
    
}

.me:hover .cp {
    display: block;

}

@media screen and (max-width:768px) {
    
    .f2 {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        gap: 1rem;
    }
}