 
.downl{
    padding:5rem;
    background-image:linear-gradient(to right, rgba(0, 0, 0, 0.665), rgba(0, 0, 0, 0.665)),url("./../../Asset/slider2.jpg") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
   background-attachment: fixed;
}


 .dlin{
    font-family: "Cabin Sketch";
    color: rgb(255, 183, 0);
    text-decoration: none;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: transform 0.8s ease-in-out;
    margin: 1rem;
 }

 .dig{
    height: 7rem;
    width:10rem;
   
 }

 .dro{
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: baseline;
 }

 .GLLd
{   text-align: center;
    color:rgb(255, 183, 0);
    font-family: "Cabin Sketch";
    font-size: 1.7rem;
}

.dlin:hover {
    transform: scale(0.9);
    border:2px double rgb(255, 183, 0);
    padding:0.5rem;
    left:-0rem;
}

@media screen and (max-width:768px)
{

    .dro{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: baseline;
         }
        
         .dlin{
            font-family: "Cabin Sketch";
            color: rgb(255, 183, 0);
            text-decoration: none;
            font-size: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            transition: transform 0.8s ease-in-out;
            margin: 1rem;
            position: relative;
            left: 1rem;;
         }
}