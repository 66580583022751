.cn{
 
    display:flex;

    justify-content: space-evenly;
    align-items: center;
    padding:2rem;
    border: 2px solid rgb(255, 183, 0)
}

.cn1{
backdrop-filter: blur(3px);
display: block;
padding:2rem;
border: 2px double rgb(255, 183, 0);
width:15rem;
border-radius: 20px;
}

.cn2{
    padding:2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.srs{
    width:400px;
    height:400px;
    border-radius: 20px;
}

.GLLd
{   text-align: center;
    color:rgb(255, 183, 0);
    font-family: "Cabin Sketch";
    font-size: 1.7rem;
}

.gblk{
    padding: 2rem;
    display: block;
    background-image:linear-gradient(to right, rgba(0, 0, 0, 0.665), rgba(0, 0, 0, 0.665)),url("./../../Asset/bulb.webp") ;
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover; 
background-attachment: fixed;
}

.kke{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Cabin Sketch";
   color: rgb(255, 251, 0);
    font-size:1rem;
    
}

.cnp{

    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    text-decoration: none;
    margin-top: -2rem;
    text-align: center;
    font-weight: bolder;
    
}

@media screen and (max-width:768px) {
 
    .cn{
 
        display:flex;
    flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding:2rem;
        border: 2px solid rgb(255, 183, 0)
    }

    .cn1{
        backdrop-filter: blur(3px);
        display: block;
        padding:2rem;
        border: 2px double rgb(255, 183, 0);
        width:13rem;
        border-radius: 20px;
        }
    
        .srs{
            width:16rem;
            height:16rem;
            border-radius: 20px;
        }
        .kke{
            position: relative;
      
        }
}