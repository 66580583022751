.bl{
    box-sizing:border-box ;
    display: block;
    gap:2rem;
}

.hg{
 padding:5rem;
background-color: rgba(128, 128, 128, 0.107);
display:flex;
justify-content: center;
align-items: center;
  text-align:center;
    font-family: Roboto,sans-serif;
   color: #204c79;

}

.hgi{
        box-sizing:border-box ;
     padding:5rem;
     padding-bottom: 3rem;
    height: 100vh;
    width:100%;
}


.para{
    padding-left:5rem;
     padding-right:5rem;
}

.hd{
        font-family: Roboto,sans-serif;
   color: #204c79;

}




@media screen and (max-width: 768px)
{

.hg{
 padding:2rem;
}

.hgi{
        box-sizing:border-box ;
     padding:2rem;
        height: 70vh;
    width:100vw;

}
.para{
    padding-left:2rem;
     padding-right:2rem;
}


}

