.P5{
    display: flex;
    padding: 4rem;
    justify-content:space-around;
    align-items: center;

    background-image:linear-gradient(to right, rgba(0, 0, 0, 0.865), rgba(0, 0, 0, 0.865)),url("./../../Asset/p5.webp") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
   background-attachment: fixed;
}

.p1l li::before {
    content: "⚡"; /* Unicode for the Font Awesome icon you want to use */
   /* Specify the Font Awesome font family */
 /* Adjust spacing between the symbol and the text */
   
  }

  .p1h
  {
  color: rgb(255, 183, 0);
  font-family: "Cabin Sketch";


  }

  .p1hy{
    text-align: center;
  }

  .p1hp{
  color:white;
  font-size: 0.9rem;
  font-family: "Poppins";
  backdrop-filter: blur(2px);
  list-style: none;
}

.P1c1{
    width:120%;
}

.P1c2{
    width:100%;
    display: flex;
    justify-content: center;
}



.p1br{
    padding:1rem;
    border: 3px dotted rgb(255, 183, 0);
    border-radius: 50%;
    transition: border 08s ease-in-out;

}

.p1br:hover {
    border-radius: 0%;
}



    
    @media screen and (max-width:768px) {
    
        .P5{
            display: flex;
            flex-direction: column;
            padding: 4rem;
            justify-content:space-around;
            align-items: center;
        
            background-image:linear-gradient(to right, rgba(0, 0, 0, 0.865), rgba(0, 0, 0, 0.865)),url("./../../Asset/p1.webp") ;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover; 
           background-attachment: fixed;
        }
    
        .p1br{
            width:100%;
            position: relative;
            left: -1rem;
            top:1.5rem;
        }
        
    }