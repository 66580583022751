.Exp{
    display: flex;

    justify-content: space-around;
    align-items: center;
    
    background: linear-gradient(to right, rgba(0, 0, 0, 0.665), rgba(0, 0, 0, 0.665)),
    url('./../../Asset/slider3.jpg');
padding: 0rem;

}

.exp1{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    
}

.exp1h{
    font-size: 2rem;
    text-align: center;
    font-family: "Bebas Neue";
    color:rgb(255, 183, 0);
}

.exp1p{
color: white;
font-size: 1rem;
text-align: justify;

}


.exp2 img{
    border-radius: 50%;
    border:2px solid rgb(255, 183, 0)
}


@media screen and (max-width:768px)
{
    .Exp{
        flex-direction: column;
    }
    .exp1{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        
    }

    .exp1p{
        color: white;
        font-size: 1rem;
        text-align: center;
        
        }

        .exp2 img{
            position: relative;
            left:3rem;
            width: 70%;
            border-radius: 50%;
            border:2px solid rgb(255, 183, 0)
        }
        
}