.cb{
    display: flex;

    justify-content: space-around;
    align-items: center;
PADDING:0REM;
    height: AUTO;

    background: linear-gradient(to right, rgba(0, 0, 0, 0.665), rgba(0, 0, 0, 0.665)),
    url('./../../Asset/slider2.jpg');

width:100vw;overflow-x: hidden;

}

.cb1{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-family: "Bebas Neue";
    text-align: center;
    font-size: 2rem;
    color: white;
padding:1rem;
}

.cb2{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}


.BBD
{   background-color: transparent;
    padding:0.5rem;
    text-align: center;
    font-style:"Poppins" ;
    transition: transform 0.4s ease-in-out;
   position: relative;
   transform: translateY(0rem);
   text-decoration: none;
   color: white;
   font-size: 0.9rem;
   margin: 0.5REM;
   border : 4px double rgb(255, 183, 0);
   cursor: pointer;
}

.BBD1{
    text-decoration: NONE;
    cursor: pointer;
}

@media screen and (max-width:768px)
{
    .cb{
        max-width:100vw;
        flex-direction: column;
        flex-wrap: wrap;
        overflow-x: hidden;
    }

    .cb1{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        font-family: "Bebas Neue";
        font-size: 1.5rem;
        color: white;
    padding:1rem;
    }
    
    .cb2{
        display: flex;
        flex-direction: column;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

}

