.video{
    position: relative;
    width: 100%;
    height: 90vh;  /* Adjust the height as needed */
    object-fit:fill;
  
}

.simulation{
    height: 100vh;
    padding: 0;
    width:100%;
}

.text{
    position: relative;
    color:rgb(255, 183, 0);
z-index: 1;
  top:-85vh;
width: 50%;
  text-align:center
}

.bh1{
    position: relative; 
    font-family: "Bebas Neue";
    font-size: 2.5rem;
    top:-1.5rem;
    left:45%;
    align-items: center;
}

.Bp{
    position: relative;
    top:1rem;
    left:1rem;
    padding: 2rem;
    width:17rem;
    font-family: "Bebas Neue";
    font-size: 1.2rem;
}



.Bp2{
    color:rgb(255, 0, 0);
}

.whi
{
    font-family: "Bebas Neue";
    color:  white;
}

.blu
{
  
font-family: "Bebas Neue";
color:  #0765bd;
}

.text1{
    position: relative;
    color:rgb(255, 183, 0);
    width:fit-content ;
z-index: 1;
top:-70vh;
left:25%;
width: 50%;

  text-align:center
}

.Bp4{
    position: relative;
   
    align-items: flex-end;
    top:1rem;
    
    padding: 2rem;
    width:17rem;
    font-family: "Bebas Neue";
    font-size: 1.2rem;
}

.tt{
    display: flex;
    justify-content: center;
    width:100%;
}

.Bp56{
    color:#0765bd;
    font-size: 2rem;
}

@media screen and (max-width:768px)
{
    

    .video{
        position: relative;
        width: 100%;
        height: 10rem;  /* Adjust the height as needed */
        object-fit:fill;
      
    }
    
    .simulation{
        height: 60rem;
        padding: 0;
        width:100%;
        margin-bottom: 5rem;;
    }
    
    .text{
        position: relative;
        color:rgb(255, 183, 0);
    z-index: 1;
      top:0vh;
    width: 100%;
      text-align:center
    }
    
    .Bp{
        position: relative;
        top:1rem;
        left:1rem;
        padding: 2rem;
        width:17rem;
        font-family: "Bebas Neue";
        font-size: 1.2rem;
    }
    
    .bh1{
        position: relative; 
        font-family: "Bebas Neue";
        font-size: 2.5rem;
        top:0rem;
        left:0rem;
        padding:1rem;
        color:  #0765bd;
        align-items: center;
    }
    
    .Bp2{
        color:rgb(255, 0, 0);
        
    }
    
    .whi
    {
        font-family: "Bebas Neue";
        color:  white;
    }
    
    .blu
    {
      
    font-family: "Bebas Neue";
    color:  #0765bd;
    }
    
    .text1{
        position: relative;
        color:rgb(255, 183, 0);
    z-index: 1;
    top:0vh;
    left:1rem;
      text-align:center
    }
    
    .Bp4{
        position: relative;
        align-items: left;
        top:1rem;
        padding: 2rem;
        width:17rem;
        font-family: "Bebas Neue";
        font-size: 1.2rem;
    }
    
    .tt{
        display: flex;
        flex-direction: column;
    }
    
    .Bp56{
        color:#0765bd;
        font-size: 2rem;
    }


}