.container1 {
    background-color:black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4rem;
    color:  rgb(255, 183, 0);
    font-size: 1rem;
    font-weight: 500;
    padding-top: 3rem;
    margin-top: 3rem;
    padding-bottom: 3rem;
    flex-wrap: wrap;
    
}

.hold1 {
    margin-left: 2rem;
}
.count {
    background-color:BLACK;
    text-align: center;
    color: rgb(255, 183, 0);
    font-size: 1rem;
    padding-bottom: 2rem;
    padding-top: 1rem;
    
}

.num {
    font-size: 3.5rem;
    font-weight: 700;
}

