.info{
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
    align-items: center;

    background-image: linear-gradient(to right,rgba(0, 0, 0, 0.649),rgba(0, 0, 0, 0.649),rgba(0, 0, 0, 0.649), rgba(255, 255, 255, 0.111) ),url(./../../Asset/sub-main-circuits.jpg);
   background-attachment: fixed;
   background-repeat: none;
   background-position: center;
   padding:3rem;
}
.a{
    border:2rem double rgb(255, 183, 0);
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width:20rem;height:28rem;
    padding:0.5rem;

}

.img{
  

 width:20rem;height:28rem;
}

.b{
    position:relative;
    width:38rem;height:18rem;
    background-color: rgba(255, 255, 255, 0.658);
    backdrop-filter: blur(4px);
    border:1px solid black;
    left:-8rem;
    top:-1rem;
    border:0.5rem solid #0765bd;
display: flex;
gap:0.02rem;
flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:1rem;
}




.h4{
    text-transform: uppercase;
    color:#0765bd;
    font-weight: bold;
    font-family: "Poppins";
}

.p{
    font-weight: bolder;
    font-family: "Poppins" 
}


@media screen and (max-width:768px) {
    .a{
        border:2rem double rgb(255, 183, 0);
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width:20rem;height:20rem;
        padding:0.5rem;
        top:-7rem
    
    }
    .img{
  

        width:20rem;height:20rem;
       }
    
       .b{
        position:relative;
height:26rem;
        background-color: rgba(255, 255, 255, 0.658);
        backdrop-filter: blur(4px);
        border:1px solid black;
        left:-12rem;
        top:5rem;
        border:0.5rem solid #0765bd;
    display: flex;
    gap:0.02rem;
    flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:1rem;
        width:19rem;
        
    }
    
    
.h4{
    text-transform: uppercase;
    color:#0765bd;
    font-weight: bold;
    font-family: "Poppins";
}

.p{
    font-weight: bolder;
    font-family: "Poppins" ;
    width:19rem;
    font-size: 0.9rem;
}
.info{
    height:40rem;
}

}