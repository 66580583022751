.wl {
    display: flex;
    justify-content: space-between;

    position: relative;
    height: auto;

   
    
    padding:1rem;
  }

  .wl{
    background-image:linear-gradient(to right,rgba(0, 0, 0, 0.578) ,rgba(0, 0, 0, 0.349)), url(./../../Asset/slider1.jpg);
    background-attachment:fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .wl2{
    background-image:linear-gradient(to right,rgba(0, 0, 0, 0.578) ,rgba(0, 0, 0, 0.349)), url(./../../Asset/slider1.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .dri {
    position: relative;
    flex: 1;
    padding: 5rem; /* Adjust as needed */
    backdrop-filter: blur(2px);

  }
  
  .dim {
    position: relative;
    flex: 1; /* Adjusted to give half as much space as .dri */
    display: flex;
    height: auto;
    align-items: flex-end; /* Adjusted to align items to the bottom */
    padding: 3rem; /* Adjust as needed */
    backdrop-filter: blur(2px);
    margin-top: 5rem;

  }
  
  .ile {
    position:relative;
    padding:1rem;
    width: 150px; /* Adjust the width as needed */
    height: 10rem; /* Maintain aspect ratio */
margin-top:4rem;
border: 1px rgb(255, 183, 0);
  }

  .hed3{
    color: rgb(255, 183, 0);
    font-family: "Cabin Sketch";
    font-size: 1.5rem;
    text-align: center;
  }
  


 
  
  

  .whatp{
    color:white;
    font-size: 1rem;
    font-family: "Poppins";
    backdrop-filter: blur(2px);
  }

  .d{

    top: -2rem;
  }

  .hed6{
    color: rgb(255, 183, 0);
    font-family: "Cabin Sketch";
    font-size: 1.3rem;
  }

  .wui li::before {
    content: "⚡"; /* Unicode for the Font Awesome icon you want to use */
   /* Specify the Font Awesome font family */
    margin-left: -25px; /* Adjust spacing between the symbol and the text */
   
  }


  .G{
    position:relative;
    top:10rem;
    flex-direction: column;
    align-items: flex-start;
    border: none;
  }

  .G1{
    backdrop-filter: blur(2px);
  }






@media screen and (max-width:768px) {
  
  .wl {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;
    height: auto;

    background-image:linear-gradient(to right,rgba(0, 0, 0, 0.578) ,rgba(0, 0, 0, 0.349)), url(./../../Asset/slider1.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    padding:1rem;
  }

  .dri {
    position: relative;
    flex: 1;
    padding: 5rem; /* Adjust as needed */
    backdrop-filter: blur(2px);
    width: 100%;
    left:-4.5rem;

  }
  
  .dim {
    position: relative;
    flex: 1; /* Adjusted to give half as much space as .dri */
    display: flex;
    height: auto;
    align-items: flex-end; /* Adjusted to align items to the bottom */
    padding: 3rem; /* Adjust as needed */
    backdrop-filter: blur(2px);
    margin-top: 5rem;
    width: 100%;
    left:-4.5rem;
  }

  .ile {
    position:relative;
    padding:1rem;
    width: 140px; /* Adjust the width as needed */
    height: 10rem; /* Maintain aspect ratio */
margin-top:4rem;
border: 1px rgb(255, 183, 0);
  }

  .k1{
    position:relative;
    top:-4rem;
    left:1rem
 
  }

  .k2{
    position:relative;
    top:-12rem;
    left:2rem
 
  }

  .k3{
    position:relative;
    top:0rem;
    left:-8.5rem
  
  }

}